<template>
  <v-card>
    <div class="tw-px-2 md:tw-px-6">
      <card-title icon="mdi-clipboard-text-outline" :is-image="false"
        >Garage Updates</card-title
      >
    </div>

    <div class="tw-px-2 md:tw-px-6 tw-py-10">
      <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-8">
        <summary-card
          v-for="(item, index) in summaries"
          :key="index"
          :count="item.count"
          :title="item.title"
          :category="item.category"
          :loading="isLoading"
        ></summary-card>
      </div>
    </div>

    <div class="tw-px-2 md:tw-px-4">
      <v-divider class="border-opacity-75"></v-divider>

      <div class="tw-flex tw-items-center tw-gap-2 tw-p-2">
        <v-icon class="c-gray-text" size="large">mdi-clock-time-four</v-icon>

        <p class="mb-0 c-gray-text tw-text-sm">Just Updated</p>
      </div>
    </div>
  </v-card>
</template>

<script>
import CardTitle from '@/components/CardTitle'

export default {
  components: {
    CardTitle,
    SummaryCard: () => import('@/components/dashboard/SummaryCard'),
  },
  props: {
    summary: {
      type: Object,
      required: true,
      default: () => ({
        total_vehicles: 0,
        repairs_count: 0,
        released_count: 0,
      }),
    },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    summaries() {
      return [
        {
          count: this.summary.total_vehicles,
          title: 'No. of vehicles in garages',
          category: 'ALL',
        },
        {
          count: this.summary.repairs_count,
          title: 'No. of Repairs in Progress',
          category: 'REPAIR_IN_PROGRESS',
        },
        {
          count: this.summary.released_count,
          title: 'No. of Released vehicles',
          category: 'VEHICLE_RELEASED',
        },
      ]
    },
  },
}
</script>
